<template>
  <div class="password-validator-container">
    <div class="validators-container">
      <span class="validator-title">Your password must contain:</span>

      <section class="validators-list">
        <div v-for="validation in Object.keys(validations)" :key="validation" class="validator">
          <span class="icon material-icons" :class="{ valid: validations[validation].isValid }">{{
            validations[validation].isValid ? 'check_circle_outline' : 'error_outline'
          }}</span>

          <span class="validator-title">{{ validations[validation].text }}</span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordValidator',
  props: {
    password: {
      type: String
    }
  },
  data() {
    return {
      validations: {
        characterAmount: {
          text: 'At least 8 characters',
          validation: this.checkCharacterAmount,
          isValid: false
        },
        upperCaseLetter: {
          text: 'At least 1 upper case letter',
          validation: this.checkHasUpperCaseLetter,
          isValid: false
        },
        lowerCaseLetter: {
          text: 'At least 1 lower case letter',
          validation: this.checkHasLowerCaseLetter,
          isValid: false
        },
        number: {
          text: 'At least 1 number',
          validation: this.checkHasNumber,
          isValid: false
        }
      }
    }
  },
  methods: {
    checkCharacterAmount(passwordToTest) {
      return passwordToTest.length >= 8
    },
    checkHasUpperCaseLetter(passwordToTest) {
      return /(?=.*[A-Z])/.test(passwordToTest)
    },
    checkHasLowerCaseLetter(passwordToTest) {
      return /(?=.*[a-z])/.test(passwordToTest)
    },
    checkHasNumber(passwordToTest) {
      return /\d/.test(passwordToTest)
    },
    checkAllValidations(newPassword) {
      this.validations.characterAmount.isValid = this.validations.characterAmount.validation(newPassword)
      this.validations.upperCaseLetter.isValid = this.validations.upperCaseLetter.validation(newPassword)
      this.validations.lowerCaseLetter.isValid = this.validations.lowerCaseLetter.validation(newPassword)
      this.validations.number.isValid = this.validations.number.validation(newPassword)
    }
  },
  watch: {
    password: {
      handler(newPassword) {
        this.checkAllValidations(newPassword)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';

.password-validator-container {
  @include flex-display(column, flex-start, flex-start);

  width: 100%;
  border-left: 8px solid var(--color-neutral-50);

  .validators-container {
    @include flex-display(column, flex-start, space-between);

    margin-left: 16px;

    .validator-title {
      letter-spacing: -0.4px;
      line-height: 18px;
      font-size: 14px;
      font-weight: 600;
      color: black !important;
    }

    .validators-list {
      @include flex-display(column, flex-start, space-between);

      gap: 4px;
      margin-top: 8px;

      .validator {
        @include flex-display(row, center, center);

        gap: 4px;

        .icon {
          font-size: 22px;
          color: var(--color-error-500);
        }

        .icon.valid {
          color: var(--color-secondary-500);
        }

        .validator-title {
          letter-spacing: -0.4px;
          line-height: 18px;
          font-size: 14px;
          font-weight: 400;
          color: $color-primary;
        }
      }
    }
  }
}
</style>
