<template>
  <!-- Using dynamic component approach in order to render the correct field component. -->
  <component :is="selectedComponent" v-bind="{ ...field, value }" @input="emitAction" @blur="$emit('blur')" @fieldUpdated="$emit('fieldUpdated')" />
</template>

<script>
import NumberInput from '@/components/dynamicForms/NumberInput.vue'
import CheckboxLabel from '@/components/dynamicForms/CheckboxLabel.vue'
import MultiSelect from '@/components/dynamicForms/MultiSelect.vue'
import SelectMenu from '@/components/dynamicForms/SelectMenu.vue'
import DateInput from '@/components/dynamicForms/DateInput.vue'
import TextInput from '@/components/dynamicForms/TextInput.vue'
import FormAlert from '@/components/dynamicForms/FormAlert.vue'
import FileInput from '@/components/dynamicForms/FileInput.vue'
import TextArea from '@/components/dynamicForms/TextArea.vue'
import Checkbox from '@/components/dynamicForms/Checkbox.vue'
import Radio from '@/components/dynamicForms/Radio.vue'
import PasswordInput from './PasswordInput.vue'

export default {
  name: 'FormFieldsComponentsSelector',
  components: {
    PasswordInput,
    NumberInput,
    CheckboxLabel,
    MultiSelect,
    SelectMenu,
    DateInput,
    TextInput,
    FormAlert,
    FileInput,
    TextArea,
    Checkbox,
    Radio
  },
  props: {
    field: Object,
    value: {
      required: true
    }
  },
  computed: {
    components() {
      return {
        // 'type.dataType': 'component'
        'password.string': 'PasswordInput',
        'text.string': 'TextInput',
        'text.integer': 'NumberInput',
        'textarea.string': 'TextArea',
        'select.string': 'SelectMenu',
        'select.array': 'MultiSelect',
        'select.integer': 'SelectMenu',
        'radio.bool': 'Radio',
        'radio.string': 'Radio',
        'checkbox.bool': 'Checkbox',
        'checkbox.string': 'CheckboxLabel',
        'checkbox.array': 'CheckboxLabel',
        'date.date': 'DateInput',
        'alert.string': 'FormAlert',
        'file.file': 'FileInput'
      }
    },

    selectedComponent() {
      const componentKey = `${this.field.type}.${this.field.dataType}`
      return this.components[componentKey]
    }
  },
  methods: {
    emitAction: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>
