<template>
  <div
    :id="`${fieldId}-select-id`"
    :name="name"
    :data-testid="`${fieldId}-select-test-id`"
    :class="{ disabled: disabled }"
    class="select-menu default-select-radius"
    @mousedown="adjustElements()"
    @keyup="adjustElements()"
  >
    <div class="select-menu-current" tabindex="0">
      <div class="select-menu-value" v-for="(option, index) in options" :key="`${option.value}-${option.label}`">
        <input :id="`${option.value}-${fieldId}`" :name="`${option.value}-${fieldId}`" :value="option.value" :checked="index === 0" class="select-menu-input" type="radio" />

        <p class="select-menu-input-text" :class="{ disabled: disabled }">{{ getPlaceholderToDisplay }}</p>
      </div>

      <span class="select-menu-icon" :class="{ inactive: !options.length }" />
    </div>
    <ul :id="`${fieldId}-list-id`" class="select-menu-list default-list-radius" :data-testid="`${fieldId}-list-test-id`">
      <li v-for="option in options" :key="`${option.value}--${option.label}-list`">
        <label
          :data-cy="`${option.value}-${fieldId}`"
          :data-testid="`${option.value}-${fieldId}`"
          :name="`${option.value}-${fieldId}`"
          :for="`${option.value}-element`"
          :aria-selected="selectedValue === option.value"
          :class="{ selected: selectedValue === option.value }"
          class="select-menu-option"
          @click="setSelectedValue(option)"
        >
          {{ option.label }}
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import controlSelectMenuStyle from '@/mixins/controlSelectMenuStyle'

export default {
  name: 'SelectMenu',
  mixins: [controlSelectMenuStyle()],
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedValue: this.value,
      currentPlaceholder: this.placeholder
    }
  },
  computed: {
    // Main select element (parent).
    selectElement() {
      return document.getElementById(`${this.fieldId}-select-id`)
    },
    // Options list element (child).
    listElement() {
      return document.getElementById(`${this.fieldId}-list-id`)
    },
    // Displays correct placeholder for select component based on the current configuration.
    getPlaceholderToDisplay() {
      if (this.currentPlaceholder) return this.currentPlaceholder

      return 'Select'
    }
  },
  methods: {
    setSelectedValue(newValue) {
      this.selectedValue = newValue.value

      // Emits the selected value to parent component.
      this.$emit('input', {
        action: 'input',
        value: newValue.value
      })
      this.$emit('fieldUpdated')
    },
    setPlaceholder() {
      const currentSelected = this.options.filter(option => option.value === this.selectedValue)

      if (currentSelected.length) {
        this.currentPlaceholder = currentSelected[0].label
      }
    },
    // Sets the correct position of the options list element on the screen.
    reposition(element, position) {
      if (!element) return

      if (position === 'bottom') {
        element.style.bottom = ''
        this.listPosition = 'bottom'
      } else {
        element.style.bottom = '32px'
        this.listPosition = 'up'
      }
    },
    // Adjust border-radius and position of main and children list elements.
    adjustElements() {
      this.checkPosition(this.listElement, this.reposition)
      this.adjustListBorderRadius(this.listElement)
      this.adjustSelectBorderRadius([this.selectElement])
    }
  },
  watch: {
    value: function (newValue) {
      this.selectedValue = newValue
    },
    selectedValue: function (newValue) {
      if (newValue && newValue?.toString() && this.options) {
        this.setPlaceholder()
      } else {
        this.currentPlaceholder = ''
      }
    }
  },
  created() {
    this.selectedValue = this.value
    this.currentPlaceholder = this.placeholder

    if (this.value) this.setPlaceholder()
  },
  mounted() {
    // Calling controlSelectMenuStyle mixin methods to set the default position and border-radius of the elements.
    this.checkPosition(this.listElement, this.reposition)
    this.addListeners()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.select-menu {
  height: 48px;
  width: 100%;
  display: block;
  position: relative;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid var(--color-primary-200);
  border-radius: 12px;
  background-color: $color-white;
  color: var(--color-primary-500);

  &:hover {
    cursor: pointer;
  }

  &:focus-within {
    border: 1px solid var(--color-primary-300);
  }

  &.disabled {
    background: var(--color-neutral-50);
    pointer-events: none;
    cursor: not-allowed;
  }

  .select-menu-current {
    position: relative;
    outline: none;
    cursor: pointer;

    &:focus {
      & + .select-menu-list {
        border: 1px solid var(--color-primary-200);
        opacity: 1;
        // Setting "animation-name: none;" to make the list visible.
        animation-name: none;

        .select-menu-option {
          cursor: pointer;

          &.selected {
            background-color: var(--color-secondary-500);
            color: $color-white;
          }
        }
      }

      .select-menu-icon:not(.inactive):before {
        transform: rotate(225deg);
        top: 3px;
      }
    }
  }

  .select-menu-icon {
    position: absolute;
    width: 40px;
    height: 38px;
    top: 5px;
    right: 1px;
    padding: 4px 8px;
    text-align: center;

    &::before {
      content: '';
      display: inline-block;
      position: relative;
      right: 0;
      border: solid var(--color-primary-300);
      border-width: 0 3px 3px 0;
      padding: 3px;
      transform: rotate(45deg);
    }
  }

  .select-menu-value {
    display: none;

    &:first-child {
      display: flex;
    }
  }

  .select-menu-input {
    display: none;

    &:checked + .select-menu-input-text {
      display: block;
      border-radius: 12px;
    }
  }

  .select-menu-input-text {
    width: 100%;
    display: none;
    margin: 0;
    padding: 10px;
    padding-right: 34px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $color-white;

    &.disabled {
      background: var(--color-neutral-50);

      > * {
        background: var(--color-neutral-50);
      }
    }
  }

  .select-menu-list {
    width: 100%;
    position: absolute;
    padding: 0;
    list-style: none;
    box-sizing: content-box;
    margin-left: -1px;
    opacity: 0;
    background-color: $color-white;
    transition: opacity 0.14s;

    // Need to use animation with delay otherwise the click event will not have time to run on placeholder, because this element disapears immediately when .select-menu-current element loses the focus.
    // This delay will not be noticed because "opacity" is set to "0".
    // "animation-fill-mode: forwards" to make the list stay hidden.
    animation-name: hide-menu;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
  }

  .select-menu-option {
    min-height: 40px;
    display: block;
    position: relative;
    padding: 12px;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    background-color: $color-white;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--color-secondary-500);
      color: $color-white;
    }
  }
}

.select-menu-list {
  height: auto;
  max-height: 300px;
  margin-top: 0px;
  border: 1px solid var(--color-secondary-50);
  z-index: 1;
  overflow: auto;
}

@keyframes hide-menu {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

@import '../../assets/styles/swoop/custom-select-menu.scss';
</style>
