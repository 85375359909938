<template>
  <label class="checkbox-wrapper">
    <input @blur="$emit('fieldUpdated')" :id="fieldId" :name="name" :checked="value" @click="clickHandler" :data-testId="fieldId" type="checkbox" />

    <p>{{ label }}</p>

    <span class="checkmark" />
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandler(event) {
      // Emits current checkbox status (value) to parent component.
      this.$emit('input', {
        action: 'input',
        value: event.target.checked
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.checkbox-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
  }

  input:checked ~ .checkmark {
    background-color: var(--color-secondary-500);
    border: 1px solid var(--color-secondary-500);
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  input:checked + p {
    color: var(--color-primary-500);
  }

  p {
    margin-top: 1px;
    margin-left: 31px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.4px;
    color: var(--color-primary-300);

    &:hover {
      cursor: pointer;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid var(--color-primary-300);
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 5.2px;
    bottom: 4px;
    width: 8px;
    height: 15px;
    border: solid $color-white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}
</style>
