<template>
  <div class="checkbox-wrapper" :class="fieldAlignment">
    <div
      v-for="option in options"
      :id="fieldId"
      :name="name"
      :key="option.value"
      :class="{ 'active-item': answers.includes(option.value), 'disabled-item': disabled }"
      class="checkbox-label"
      @click="setSelectedValue(option.value)"
    >
      <input :id="`${fieldId}-${option.value}`" :name="`${name}-${option.value}`" :checked="answers.includes(option.value)" role="checkbox" type="checkbox" />

      <span class="checkmark" />

      <label :for="`${name}-${option.value}`" @click="setSelectedValue(option.value)">{{ option.label }}</label>

      <div v-if="option.tooltip" class="help material-icons tooltip-helper" @mouseover="trackAmplitudeToolTipEvent(option)">
        help
        <span class="tooltip">{{ option.tooltip }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxLabel',
  data() {
    return {
      answers: []
    }
  },
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    fieldAlignment: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setSelectedValue(newValue) {
      if (this.answers?.includes(newValue)) {
        this.answers = this.answers.filter(answer => answer !== newValue)
      } else {
        this.answers.push(newValue)
      }

      // Emits current checkbox status (values) to parent component.
      this.$emit('input', {
        action: 'input',
        value: this.answers
      })
    },
    trackAmplitudeToolTipEvent(formItem) {
      this.$ma.trackEvent({
        eventType: 'Click info button',
        eventProperties: {
          question: formItem.label || ''
        }
      })
    }
  },
  watch: {
    disabled() {
      this.answers = []
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';
@import '@/assets/styles/swoop/helper-tooltip.scss';

.checkbox-wrapper {
  width: 100%;

  .checkbox-label {
    @include flex-display(row, center, flex-start);

    height: 48px;
    margin: 8px 0;
    position: relative;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid var(--color-primary-200);
    color: var(--color-primary-300);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.45px;
    letter-spacing: -0.46px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &.active-item {
      border: 1px solid var(--color-secondary-500);
      background-color: var(--color-secondary-50);
      color: var(--color-primary-500);
    }

    &.disabled-item {
      border: 1px solid var(--color-primary-200);
      background-color: var(--color-neutral-50);
      pointer-events: none;
      color: var(--color-primary-300);

      &:hover {
        cursor: not-allowed;
      }
    }

    input[type='checkbox'] {
      outline: none;
      -webkit-appearance: none;
    }

    input:checked ~ .checkmark {
      background-color: var(--color-secondary-500);
      border: 1px solid var(--color-secondary-500);
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    label {
      margin-left: 24px;

      &:hover {
        cursor: pointer;
      }
    }

    .checkmark {
      position: absolute;
      top: 13px;
      left: 12px;
      height: 20px;
      width: 20px;
      border: 2px solid var(--color-primary-300);
      border-radius: 4px;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
      left: 5.2px;
      bottom: 4px;
      width: 8px;
      height: 15px;
      border: solid $color-white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }

    .tooltip-helper {
      margin: 0;
      position: absolute;
      right: 0;
      margin-right: -42px;
    }
  }

  &.horizontal {
    @include flex-display(row, center, space-between);

    gap: 8px;

    .checkbox-label {
      margin: 0;
    }

    .checkbox-label {
      width: 50%;
    }
  }

  @media only screen and (max-width: 830px) {
    &.horizontal {
      .checkbox-label {
        padding: 8px;
      }

      .checkbox-label:first-child {
        margin-right: 2px;
      }

      .checkbox-label:last-child {
        margin-left: 2px;
      }
    }
  }
}
</style>
