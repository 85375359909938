<template>
  <div class="file-field field">
    <input
      :id="fieldId"
      :name="name"
      :data-testid="fieldId"
      :disabled="disabled"
      @change="emitFiles"
      type="file"
      role="input"
      :class="`file-input ${currentFileName ? 'hidden' : ''}`"
      :accept="accepts"
      @blur="$emit('fieldUpdated')"
    />

    <div :class="`${currentFileName ? '' : 'hidden'} file-description`">
      {{ currentFileName }}
      <button :data-testid="`delete-button-${fieldId}`" @click="emitDelete" :class="`${currentFileName ? '' : 'hidden'} remove-file-button`">
        <span class="material-icons">
          close
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileInput',
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    accepts: {
      required: false,
      default: '*'
    }
  },
  data() {
    return {
      currentFileName: this.value?.name || ''
    }
  },
  methods: {
    emitFiles(event) {
      if (!event?.target || !event?.target?.files) return

      this.$emit('input', {
        action: 'fileUpload',
        value: {
          fieldName: this.name,
          file: event.target.files[0],
          info: { title: event.target.files[0].name, fieldId: this.fieldId }
        }
      })

      this.currentFileName = event.target.files[0].name

      document.getElementById(this.fieldId).value = ''
    },
    emitDelete() {
      this.currentFileName = ''
      this.$emit('input', {
        action: 'fileDelete',
        value: this.value?.documentId
      })
    }
  },
  created() {
    this.currentFileName = this.value?.name || ''
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';

.hidden {
  display: none !important;
}

.file-field {
  width: 100%;

  .file-input,
  .file-description {
    width: 100%;
    min-height: 48px;
    padding: 8px 12px;
    border: 1px solid var(--color-primary-200);
    border-radius: 12px;
    box-sizing: border-box;
    background: $color-white;
    color: var(--color-primary-500);

    &:focus {
      border: 1px solid var(--color-primary-300);
      box-shadow: none;
    }
  }

  .file-description {
    @include flex-display(row, center, space-between);

    .remove-file-button {
      @include flex-display(row, center, center);

      position: relative;
      left: 4px;
      padding: 0;
      border: none;
      font-size: 22px;
      background: transparent;
      color: var(--color-primary-500);

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
