var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validator",staticClass:"form-container",class:{ 'blend-background': _vm.isNewOnboardingJourney },attrs:{"role":"form","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [(_vm.loading)?_c('div',{staticClass:"loader-container"},[_c('div',[_c('Spinner',{attrs:{"size":64,"loader":true,"color":""}})],1)]):[(_vm.title)?_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.subtitle)?_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.subtitle)+" "),(_vm.subtitleWithGoBackAction && !_vm.isSingleJourney())?_c('strong',{on:{"click":function () { return _vm.goBack('/goals'); }}},[_vm._v(" "+_vm._s(_vm.subtitleWithGoBackAction.text)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"questions-container"},[_vm._l((_vm.currentForm),function(field,index){return [(_vm.shouldDisplay(field))?_c('div',{key:field.name,staticClass:"field-wrapper",class:[{ 'with-child': field.childOf && field.dependencyRule }]},[(_vm.shouldDisplay(field) && !(field.type === 'checkbox' && field.childOf))?_c('ValidationProvider',{staticClass:"validator-provider-container",class:[{ 'child-container': field.childOf }],attrs:{"debounce":field.debounce || 0,"rules":field.rules,"custom-messages":_vm.messageGenerator(field.rules, field.errorMessages),"tag":"div","vid":field.confirmed ? 'confirmation' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"field",class:{ invalid: !valid }},[(field.childOf)?_c('span',{staticClass:"child"}):_vm._e(),_c('div',{staticClass:"field-data"},[_c('div',{staticClass:"texts-wrapper"},[(field.label)?_c('label',{staticClass:"label",class:[{ 'child-label': field.childOf, error: errors.length }, field.fieldAlignment],attrs:{"for":("" + (field.name))}},[_vm._v(" "+_vm._s(field.label)+" ")]):_vm._e(),(errors.length && field.errorMessages)?_c('div',{staticClass:"errors-container"},[_c('em',{staticClass:"icon material-icons"},[_vm._v("error_outline")]),_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e()]),[(field.tooltip)?_c('div',{staticClass:"help material-icons",on:{"mouseover":function($event){return _vm.trackAmplitudeToolTipEvent(field)}}},[_vm._v(" help "),_c('span',{staticClass:"tooltip"},[_vm._v(_vm._s(field.tooltip))])]):_c('span',{staticClass:"help no-tooltip"})],_c('div',{staticClass:"input-wrapper",class:[{ 'no-tooltip': !field.tooltip, both: field.type === 'alert' }, field.side]},[_c('FormFieldsComponentsSelector',{attrs:{"field":Object.assign({}, field, {disabled: _vm.checkIsDisabled(field.name)}),"value":field.value},on:{"input":function (emmitedInput) { return _vm.handleEmittedInput(emmitedInput, field, index); },"fieldUpdated":function () { return _vm.trackAmplitudeFieldUpdated(field.name); }}}),(index + 1 < _vm.form.length && _vm.form[index + 1].type === 'checkbox' && _vm.form[index + 1].childOf)?_c('Checkbox',_vm._b({on:{"input":function (emmitedInput) {
                        _vm.handleValueChange(_vm.form[index + 1], emmitedInput.value)
                      },"blur":function () { return _vm.trackAmplitudeFieldUpdated(field.name); }}},'Checkbox',Object.assign({}, _vm.form[index + 1]),false)):_vm._e()],1)],2)])]}}],null,true)}):_vm._e()],1):_vm._e()]})],2),_c('div',{staticClass:"controls"},[(_vm.isFormSkippable)?_c('button',{staticClass:"skip-button",on:{"click":_vm.skipForm}},[_vm._v("Skip")]):_vm._e(),_c('div',{staticClass:"button-wrapper"},[(_vm.enableAgreeText)?_c('p',{staticClass:"agree-text"},[_vm._v(" By clicking on this button, you agree to our "),_c('a',{attrs:{"href":_vm.$config.whitelabel.links.privacyPolicyLink,"target":"_blank","rel":"noopener"}},[_vm._v("Privacy Policy")]),_vm._v(" & "),_c('a',{attrs:{"href":_vm.$config.whitelabel.links.termsAndConditionsLink,"target":"_blank","rel":"noopener"}},[_vm._v("Terms of Use")]),_vm._v(". ")]):_vm._e(),_c('Button',{staticClass:"next-button",attrs:{"buttonProps":{
            id: 'next-button',
            buttonText: _vm.submitState.loading ? 'One moment...' : _vm.submitButtonText,
            isLoading: _vm.submitState.loading || !_vm.formIsTouched,
            loadingPercentage: _vm.submitState.loadingPercentage,
            forwardArrowEnabled: !_vm.submitState.loading
          },"type":"submit"},on:{"click":function () { return validate().then(function (valid) { return _vm.submit(valid); }); }}})],1)])]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }