<template>
  <div class="radio-wrapper" :class="fieldAlignment">
    <div
      v-for="option in options"
      :id="fieldId"
      :key="`radio-${option.value}`"
      :class="{ 'active-item': currentValue === option.value || currentValue === option.value.toString(), 'disabled-item': disabled }"
      class="radio"
      @click="setSelectedValue(option.value)"
    >
      <input :id="`${fieldId}-${option.value}`" :name="`${name}-${option.value}`" :value="option.value" role="radio" type="radio" v-model="currentValue" />

      <label :for="`${name}-${option.value}`">{{ option.label }}</label>

      <div v-if="option.tooltip" class="help material-icons tooltip-helper" @mouseover="trackAmplitudeToolTipEvent(option)">
        help
        <span class="tooltip">{{ option.tooltip }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Radio',
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      default: null
    },
    icon: {
      type: String,
      default: ''
    },
    fieldAlignment: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  methods: {
    setSelectedValue(newValue) {
      this.currentValue = newValue

      // Emits current radio status (value) to parent component.
      this.$emit('input', {
        action: 'input',
        value: newValue
      })
      this.$emit('fieldUpdated')
    },
    trackAmplitudeToolTipEvent(formItem) {
      this.$ma.trackEvent({
        eventType: 'Click info button',
        eventProperties: {
          question: formItem.label || ''
        }
      })
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue || false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';
@import '@/assets/styles/swoop/helper-tooltip.scss';

.radio-wrapper {
  width: 100%;

  .radio {
    @include flex-display(row, center, flex-start);

    height: 48px;
    margin: 8px 0;
    position: relative;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid var(--color-primary-200);
    color: var(--color-primary-300);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.45px;
    letter-spacing: -0.46px;

    &:hover {
      border: 1px solid var(--color-primary-300);
      cursor: pointer;
    }

    &:focus-within {
      border: 1px solid var(--color-primary-300);
      outline: none;
    }

    & > *:hover {
      cursor: pointer;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active-item {
      border: 1px solid var(--color-secondary-500);
      background-color: var(--color-secondary-50);
    }

    &.disabled-item {
      border: 1px solid var(--color-primary-200);
      background-color: var(--color-neutral-50);
      pointer-events: none;
      color: var(--color-primary-300);

      &:hover {
        cursor: not-allowed;
      }
    }

    input + label {
      margin-left: 8px;
    }

    input[type='radio']:checked + label {
      color: var(--color-primary-500);
    }

    input[type='radio'] {
      -webkit-appearance: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px !important;
      height: 16px !important;
      border-radius: 50%;
      outline: none;
      border: 2px solid var(--color-primary-300);
    }

    input[type='radio']:before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    input[type='radio']:checked:before {
      background: var(--color-secondary-500);
    }

    input[type='radio']:checked {
      border-color: var(--color-secondary-500);
    }

    .tooltip-helper {
      margin: 0;
      position: absolute;
      right: 0;
      margin-right: -42px;
    }
  }

  &.horizontal {
    @include flex-display(row, center, space-between);

    .radio {
      margin: 0;
    }

    .radio:first-child {
      width: 50%;
      margin-right: 4px;
    }

    .radio:last-child {
      width: 50%;
      margin-left: 4px;
    }
  }

  @media only screen and (max-width: 830px) {
    // When on small screen, forcing the orientation to vertical to have a better visualization.
    &.horizontal {
      @include flex-display(column, center, space-between);

      .radio:first-child {
        width: 100%;
        margin: 0;
        margin-bottom: 4px;
      }

      .radio:last-child {
        width: 100%;
        margin: 0;
        margin-top: 4px;
      }
    }
  }
}
</style>
