<template>
  <div class="date-input-wrapper">
    <SelectMenu :value="month" :options="months" :data-testid="`${fieldId}-month`" :disabled="disabled" class="date-input" fieldId="month" name="month" placeholder="Month" @input="handleMonthChange" @fieldUpdated="$emit('fieldUpdated')" />

    <SelectMenu :value="year" :options="years" :data-testid="`${fieldId}-year`" :disabled="disabled" class="date-input" fieldId="year" name="year" placeholder="Year" @input="handleYearChange" @fieldUpdated="$emit('fieldUpdated')" />
  </div>
</template>

<script>
import SelectMenu from '@/components/dynamicForms/SelectMenu.vue'

export default {
  name: 'DateInput',
  components: {
    SelectMenu
  },
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      day: null,
      month: null,
      year: null,
      days: [],
      months: [],
      years: []
    }
  },
  mounted() {
    this.generateDays()
    this.generateMonths()
    this.generateYears()

    const currentDate = new Date(this.value ? this.value * 1000 : '')

    if (currentDate && !isNaN(currentDate.getTime())) {
      this.$nextTick(() => {
        this.day = 1
        this.month = currentDate.getMonth() + 1
        this.year = currentDate.getFullYear()
      })
    }
  },
  computed: {
    selectedDate() {
      return `${this.day}${this.month + 1}${this.year}`
    }
  },
  watch: {
    month() {
      this.generateMonths()
    },
    year() {
      this.generateYears()
    }
  },
  methods: {
    getDate() {
      const date = new Date(this.year, this.month, this.day)

      date.setUTCHours(0, 0, 0, 0)

      return date.toISOString()
    },
    emitDate() {
      if (this.year && this.month) {
        const date = this.getDate()

        this.$emit('input', {
          action: 'input',
          value: date
        })
      }
    },
    generateDays() {
      const year = this.year || new Date().getFullYear()
      const month = this.month || new Date().getMonth() + 1
      const date = new Date(year, month, 0).getDate()

      const days = []

      for (let i = 1; i <= date; i++) {
        days.push({ label: i, value: i })
      }

      this.days = days

      // If user pick 31 January then change to February it will show 28.
      if (this.day > date) {
        this.day = date
      }
    },
    generateMonths(short) {
      const format = short ? 'short' : 'long'

      this.months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (month) {
        return {
          label: new Date(2000, month).toLocaleString({}, { month: format }),
          value: month + 1
        }
      })
    },
    generateYears() {
      const year = new Date().getFullYear()
      const years = []

      for (let i = year; i > year - 100; i--) {
        years.push({
          label: i,
          value: i
        })
      }

      this.years = years
    },
    handleMonthChange(newMonth) {
      this.month = newMonth.value

      this.emitDate()
    },
    handleYearChange(newYear) {
      this.year = newYear.value

      this.emitDate()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_mixins.scss';

.date-input-wrapper {
  @include flex-display(row, center, space-between);

  width: 100%;
  gap: 8px;

  .custom-select {
    &:nth-child(2) {
      margin: 0 8px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--color-neutral-50);
  }

}

</style>
