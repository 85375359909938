<template>
  <multiselect
    :id="fieldId"
    :name="name"
    :data-cy="fieldId"
    :data-testid="fieldId"
    :options="options"
    :multiple="true"
    v-model="selected"
    placeholder=""
    label="label"
    track-by="value"
    @input="setSelected"
    @close="$emit('blur')"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'MultiSelect',
  components: { Multiselect },
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: []
    }
  },
  methods: {
    setSelected(newValue) {
      this.selected = newValue

      // Emits the selected value to parent component.
      this.$emit('input', {
        action: 'input',
        value: newValue
      })
    }
  },
  watch: {
    value: function (newValue) {
      this.selected = newValue || []
    }
  },
  created() {
    this.selected = this.value || []
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.multiselect {
  /deep/.multiselect__content-wrapper {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &.multiselect--above /deep/.multiselect__content-wrapper {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.multiselect--above.multiselect--active /deep/.multiselect__tags {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  &.multiselect--active:not(.multiselect--above) /deep/.multiselect__tags {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  /deep/.multiselect__select {
    height: 48px;
    transition: none;

    &::before {
      content: '';
      display: inline-block;
      position: relative;
      top: 10px;
      right: 0;
      border: solid var(--color-primary-300);
      border-width: 0 3px 3px 0;
      padding: 3px;
      transform: rotate(45deg);
    }
  }

  /deep/.multiselect__tags {
    min-height: 48px;
    padding: 12px 42px 0 14px;
    border: 1px solid var(--color-primary-200);
    border-radius: 12px;

    .multiselect__tags-wrap .multiselect__tag {
      background-color: var(--color-secondary-500);

      .multiselect__tag-icon {
        background-color: var(--color-secondary-500);

        &::after {
          color: $color-white;
        }

        &:hover {
          background-color: var(--color-secondary-500);
        }
      }
    }

    &:focus-within {
      border: 1px solid var(--color-primary-300);
    }

    .multiselect__input {
      border: none;
      border-radius: 0;
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }

  /deep/.multiselect__content-wrapper {
    margin-bottom: -1px;
    border: 1px solid var(--color-primary-200);
    color: var(--color-primary-500);

    .multiselect__content {
      .multiselect__element {
        .multiselect__option--selected {
          color: var(--color-secondary-500);
        }

        .multiselect__option--highlight {
          background-color: var(--color-secondary-500);
          color: $color-white;

          &::after {
            background-color: var(--color-secondary-500);
          }
        }

        li .multiselect__option:last-child {
          font-size: 14px;
        }
      }
    }
  }
}

.multi-select-error {
  position: relative;
  top: 5px;
  padding: 0;
  color: var(--color-warning-600);
}
</style>
