<template>
  <div class="currency-input-wrapper">
    <input @blur="$emit('fieldUpdated')" :id="fieldId" :name="name" :data-testId="fieldId" :placeholder="placeholder" :disabled="disabled" v-model="formattedValue" inputmode="numeric" v-currency />

    <span v-if="icon">{{ placeholderIcon }}</span>
  </div>
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formattedValue: ''
    }
  },
  computed: {
    placeholderIcon() {
      if (this.icon === 'currency') return this.$config.whitelabel.country.currency.defaultCurrencySymbol
      if (this.icon === 'percentage') return '%'
      return ''
    }
  },
  watch: {
    formattedValue: function (newValue) {
      // Emits current formatted value to parent component.
      this.$emit('input', {
        action: 'input',
        value: newValue
      })
    },
    value: function (newValue) {
      // If a new value is passed from the parent, it is assigned as a formatted value.
      this.formattedValue = newValue
    }
  },
  created() {
    this.formattedValue = this.value || ''
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.currency-input-wrapper {
  width: 100%;
  position: relative;

  input {
    height: 48px;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid var(--color-primary-200);
    border-radius: 12px;
    background-color: $color-white;
    color: var(--color-primary-500);
    padding-left: 30px;

    &:disabled {
      background-color: var(--color-neutral-50);
    }

    &:focus {
      border: 1px solid var(--color-primary-300);
      box-shadow: none;
    }

    &:focus-visible {
      border: 1px solid var(--color-primary-300);
      box-shadow: none;
      outline: none;
    }
  }

  span {
    position: absolute;
    left: 15px;
    top: 12px;
    font-size: 14px;
    color: var(--color-primary-500);
  }
}
</style>
