<template>
  <div class="form-alert-container">
    <h4 class="title">
      <em class="icon material-icons">{{ icons[metadata.type] }}</em>

      {{ metadata.title }}
    </h4>
    <p class="subtitle" v-html="metadata.description || ''" />
  </div>
</template>

<script>
export default {
  name: 'FormAlert',
  props: {
    metadata: Object
  },
  computed: {
    icons() {
      return {
        warning: 'warning_amber'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';

.form-alert-container {
  @include flex-display(column, flex-start, center);

  height: 118px;
  padding: 24px;
  border-radius: 12px;
  background-color: var(--color-tertiary-50);

  & > * {
    margin: 0;
    font-size: 14px;
    line-height: 18.2px;
    letter-spacing: -0.35px;
    color: var(--color-primary-500);
  }

  .title {
    @include flex-display(row, center, center);

    font-weight: 600;

    em {
      margin-right: 8px;
      color: var(--color-secondary-500);
    }
  }

  .subtitle {
    margin-top: 16px;
    font-weight: 400;

    /deep/a {
      color: var(--color-secondary-500);
    }
  }

  @media only screen and (max-width: 830px) {
    height: auto;
  }
}
</style>
