import whitelabel from '@/config/whitelabel'

export default {
  methods: {
    isSingleJourney() {
      const goalTypesEnabled = [whitelabel.journey.grantsEnabled, whitelabel.journey.loansEnabled, whitelabel.journey.equityEnabled]
      return (
        goalTypesEnabled.filter(items => {
          return items
        }).length === 1
      )
    }
  }
}
