<template>
  <div class="container">
    <input @blur="$emit('fieldUpdated')" :id="fieldId" :name="name" :data-testid="fieldId" :placeholder="placeholder" type="password" v-model="currentValue" class="password-input" role="input" />
    <PasswordValidator v-if="currentValue" :password="currentValue" class="password-validation" />
  </div>
</template>

<script>
import PasswordValidator from '@/components/PasswordValidator'

export default {
  name: 'PasswordInput',
  components: {
    PasswordValidator
  },
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  watch: {
    currentValue(newValue) {
      this.$emit('input', {
        action: 'input',
        value: newValue
      })
    },
    value(newValue) {
      this.currentValue = newValue
    }
  },
  created() {
    this.currentValue = this.value
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.container {
  width: 100%;
  padding: 0;
}

.password-input {
  height: 48px;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid var(--color-primary-200);
  border-radius: 12px;
  background-color: $color-white;
  color: var(--color-primary-500);

  &:disabled {
    background-color: var(--color-neutral-50);
  }

  &:focus {
    border: 1px solid var(--color-primary-300);
    box-shadow: none;
  }

  &:focus-visible {
    border: 1px solid var(--color-primary-300);
    box-shadow: none;
    outline: none;
  }
}
.password-validation {
  margin-top: 16px;
}
</style>
