<template>
  <textarea @blur="$emit('fieldUpdated')" :id="fieldId" :name="name" :data-testid="fieldId" :placeholder="placeholder" :disabled="disabled" class="text-area" v-model="currentValue" role="input" />
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  watch: {
    currentValue(newValue) {
      this.$emit('input', {
        action: 'input',
        value: newValue
      })
    },
    value(newValue) {
      this.currentValue = newValue
    }
  },
  created() {
    this.currentValue = this.value
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.text-area {
  width: 100%;
  height: 76px;
  border: 1px solid var(--color-primary-200);
  border-radius: 12px;
  box-sizing: border-box;
  background: $color-white;
  color: var(--color-primary-500);

  &:focus {
    border: 1px solid var(--color-primary-300);
    box-shadow: none;
  }
}
</style>
