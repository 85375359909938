/* eslint-disable camelcase */
import whitelabelConfig from '@/config/whitelabel'

const email = (value, message) => {
  return message
}

const required = (value, message) => {
  return message
}

const min_value = (value, message) => {
  return `${message} ${whitelabelConfig.country.currency.defaultCurrencySymbol}${value}`
}

const min_length = (value, message) => {
  return message
}

const regex = (value, message) => {
  return message
}

const confirmed = (value, message) => {
  return message
}

const messageGenerators = {
  regex,
  confirmed,
  required,
  min_value,
  min_length,
  email
}

const generateErrorMessage = (ruleName, ruleValue, errorMessage) => {
  return messageGenerators[ruleName](ruleValue, errorMessage)
}

export default (rules, errorMessages) => {
  if (!rules || !errorMessages) return

  const finalErrorMessages = {}

  Object.keys(rules).forEach(key => {
    finalErrorMessages[key] = generateErrorMessage(key, rules[key], errorMessages[key])
  })

  return finalErrorMessages
}
