const findMatchingSelectMenuValue = (options, value) => {
  if (!value) return null
  return options[options.findIndex(option => option.value === value.toString())] || null
}

const convertValueBasedOnType = {
  select: (value, options) => {
    try {
      // handles checking if the value exists in the available options for that field
      return JSON.parse(value).filter(option => findMatchingSelectMenuValue(options, option.value || option)) || null
    } catch (e) {
      // handles turning non JSON option arrays into options e.g. [ 34 ] ->  [ {value: 'val', label: 'label'} ]
      if (Array.isArray(value)) {
        return value.map(val => val && findMatchingSelectMenuValue(options, val)).filter(val => val) || null
      }
      return value || null
    }
  },
  array: (value, options) => {
    try {
      // handles checking if the value exists in the available options for that field
      return JSON.parse(value).filter(option => findMatchingSelectMenuValue(options, option.value || option)) || null
    } catch (e) {
      // handles turning non JSON option arrays into options e.g. [ 34 ] ->  [ {value: 'val', label: 'label'} ]
      if (Array.isArray(value)) {
        return value.map(val => val && findMatchingSelectMenuValue(options, val)).filter(val => val) || null
      }
      return value || null
    }
  },
  date: value => {
    return new Date(value).getTime() / 1000
  },
  file: value => {
    return JSON.parse(value)
  }
}

export default (value, fieldType, fieldOptions) => {
  if (!value) return null

  if (fieldType === 'integer' && fieldOptions) {
    return findMatchingSelectMenuValue(fieldOptions, value)?.value || null
  }

  const convertFunction = convertValueBasedOnType[fieldType]

  if (convertFunction) {
    return convertFunction(value, fieldOptions)
  }

  return value
}
