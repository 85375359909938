<template>
  <ValidationObserver class="form-container" :class="{ 'blend-background': isNewOnboardingJourney }" ref="validator" role="form" tag="div" #default="{ validate }">
    <div class="loader-container" v-if="loading">
      <div>
        <Spinner :size="64" :loader="true" color />
      </div>
    </div>
    <template v-else>
      <h1 class="title" v-if="title">{{ title }}</h1>
      <p class="subtitle" v-if="subtitle">
        {{ subtitle }}
        <strong v-if="subtitleWithGoBackAction && !isSingleJourney()" @click="() => goBack('/goals')">
          {{ subtitleWithGoBackAction.text }}
        </strong>
      </p>
      <div class="questions-container">
        <template v-for="(field, index) in currentForm">
          <div v-if="shouldDisplay(field)" :key="field.name" class="field-wrapper" :class="[{ 'with-child': field.childOf && field.dependencyRule }]">
            <ValidationProvider
              :debounce="field.debounce || 0"
              v-if="shouldDisplay(field) && !(field.type === 'checkbox' && field.childOf)"
              :rules="field.rules"
              :custom-messages="messageGenerator(field.rules, field.errorMessages)"
              :class="[{ 'child-container': field.childOf }]"
              class="validator-provider-container"
              tag="div"
              #default="{ valid, errors }"
              :vid="field.confirmed ? 'confirmation' : null"
            >
              <div class="field" :class="{ invalid: !valid }">
                <span v-if="field.childOf" class="child" />
                <div class="field-data">
                  <div class="texts-wrapper">
                    <label v-if="field.label" :for="`${field.name}`" class="label" :class="[{ 'child-label': field.childOf, error: errors.length }, field.fieldAlignment]">
                      {{ field.label }}
                    </label>
                    <div class="errors-container" v-if="errors.length && field.errorMessages">
                      <em class="icon material-icons">error_outline</em>

                      <span class="error-message">
                        {{ errors[0] }}
                      </span>
                    </div>
                  </div>
                  <template>
                    <div v-if="field.tooltip" class="help material-icons" @mouseover="trackAmplitudeToolTipEvent(field)">
                      help

                      <span class="tooltip">{{ field.tooltip }}</span>
                    </div>
                    <span v-else class="help no-tooltip" />
                  </template>
                  <div class="input-wrapper" :class="[{ 'no-tooltip': !field.tooltip, both: field.type === 'alert' }, field.side]">
                    <FormFieldsComponentsSelector
                      :field="{ ...field, disabled: checkIsDisabled(field.name) }"
                      :value="field.value"
                      @input="emmitedInput => handleEmittedInput(emmitedInput, field, index)"
                      @fieldUpdated="() => trackAmplitudeFieldUpdated(field.name)"
                    />
                    <Checkbox
                      v-if="index + 1 < form.length && form[index + 1].type === 'checkbox' && form[index + 1].childOf"
                      v-bind="{ ...form[index + 1] }"
                      @input="
                        emmitedInput => {
                          handleValueChange(form[index + 1], emmitedInput.value)
                        }
                      "
                      @blur="() => trackAmplitudeFieldUpdated(field.name)"
                    />
                  </div>
                </div>
              </div>
            </ValidationProvider>
          </div>
        </template>
      </div>
      <div class="controls">
        <button v-if="isFormSkippable" class="skip-button" @click="skipForm">Skip</button>
        <div class="button-wrapper">
          <p v-if="enableAgreeText" class="agree-text">
            By clicking on this button, you agree to our <a :href="$config.whitelabel.links.privacyPolicyLink" target="_blank" rel="noopener">Privacy Policy</a> &
            <a :href="$config.whitelabel.links.termsAndConditionsLink" target="_blank" rel="noopener">Terms of Use</a>.
          </p>
          <Button
            :buttonProps="{
              id: 'next-button',
              buttonText: submitState.loading ? 'One moment...' : submitButtonText,
              isLoading: submitState.loading || !formIsTouched,
              loadingPercentage: submitState.loadingPercentage,
              forwardArrowEnabled: !submitState.loading
            }"
            type="submit"
            class="next-button"
            @click="() => validate().then(valid => submit(valid))"
          />
        </div>
      </div>
    </template>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { updateFormFileToUploadRoutine, formMetaDataGetters, updateFormFileToDeleteRoutine } from '@/store/modules/formMetaData/routines'
import { pageOverlayGetters, pageOverlayRoutine } from '@/store/modules/pageOverlay/routines'
import FormFieldsComponentsSelector from '@/components/dynamicForms/FormFieldsComponentsSelector.vue'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import checkSingleGoalJourney from '@/mixins/checkSingleGoalJourney'
import messageGenerator from '@/config/vee-validate.message-generators'
import Checkbox from '@/components/dynamicForms/Checkbox.vue'
import { toastTopCenterOptions } from '@/config/vue-toast'
import { getMetaData } from '@/api/metaData'
import Spinner from '@/components/Spinner'
import Button from './Button'

export default {
  name: 'DynamicForm',
  mixins: [checkSingleGoalJourney, amplitudeTracking],
  components: {
    ValidationObserver,
    ValidationProvider,
    FormFieldsComponentsSelector,
    Checkbox,
    Spinner,
    Button
  },
  props: {
    form: {
      type: Array,
      required: true
    },
    title: {
      required: false,
      type: String
    },
    subtitle: {
      type: String
    },
    subtitleWithGoBackAction: {
      default: () => {}
    },
    submitButtonText: {
      type: String,
      default: 'Next'
    },
    loading: {
      type: Boolean
    },
    submitState: {
      loading: Boolean,
      loadingPercentage: Number
    },
    isNewOnboardingJourney: {
      required: false,
      default: false,
      type: Boolean
    },
    isFormSkippable: {
      required: false,
      default: false,
      type: Boolean
    },
    enableAgreeText: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      currentForm: this.form,
      formIsTouched: false,
      disabledFields: [],
      fieldsRules: {},
      fileInfo: null,
      messageGenerator,
      fileToUpload: null,
      fetchDependancyMap: {}
    }
  },
  mounted() {
    if (this.formFileToUpload) this.setFormFileToUpload(null)
    // enables next button by default if partially filled forms (skippable) are allowed
    if (this.isFormSkippable) this.formIsTouched = true

    this.createFetchDependancyMap(this.form)
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN,
      formFileToUpload: formMetaDataGetters.FORM_FILE_TO_UPLOAD,
      formFileToDelete: formMetaDataGetters.FORM_FILE_TO_DELETE
    }),
    fieldsActions() {
      return {
        input: this.handleValueChange,
        fileUpload: this.handleFileUpload,
        fileDelete: this.handleFileDelete
      }
    }
  },
  methods: {
    ...mapActions({
      setPageOverlay: pageOverlayRoutine.TRIGGER,
      setFormFileToUpload: updateFormFileToUploadRoutine.TRIGGER,
      setFormFileToDelete: updateFormFileToDeleteRoutine.TRIGGER
    }),
    skipForm() {
      this.$emit('skip-form')
    },
    goBack() {
      if (this.isPageOverlayOpen) {
        this.setPageOverlay(!this.isPageOverlayOpen)
      } else {
        this.$emit('previous-click')
      }
    },
    trackAmplitudeFieldUpdated(fieldName) {
      const foundField = this.currentForm.find(formField => formField.name === fieldName)
      if (foundField && (foundField.type === 'file' || foundField.value)) {
        this.$ma.trackEvent({
          eventType: 'Dynamic form field update',
          eventProperties: {
            field: fieldName,
            value: foundField.type === 'file' ? foundField.type : foundField.value
          }
        })
      }
    },
    trackAmplitudeToolTipEvent(formItem) {
      this.$ma.trackEvent({
        eventType: 'Click info button',
        eventProperties: {
          question: formItem.label || ''
        }
      })
    },
    checkDependencies(currentField) {
      const foundFieldIndex = this.currentForm.findIndex(formField => formField?.name === currentField?.dependsOn)

      if (foundFieldIndex === -1) return true

      return this.currentForm[foundFieldIndex]?.value
    },
    checkDependenciesOn(currentField, dependencies = null) {
      // handles question dependancy where the single value must be true to render child question/s
      const _dependencies = dependencies || currentField.dependencies

      return !_dependencies
        .map(dependantField => {
          const foundFieldIndex = this.currentForm.findIndex(formField => formField?.name === dependantField.field)

          if (foundFieldIndex === -1) {
            throw new Error(`No dependant field found with name ${dependantField.field} for field ${currentField.name}.`)
          }

          return this.currentForm[foundFieldIndex].value?.toString() === dependantField.value?.toString()
        })
        .includes(false)
    },
    checkDependenciesOr(currentField, dependencies = null) {
      // handles question dependancy where any value from a list of values can be true to render child question/s
      const _dependencies = dependencies || currentField.dependencies
      return _dependencies
        .map(dependantField => {
          const foundFieldIndex = this.currentForm.findIndex(formField => formField?.name === dependantField.field)

          if (foundFieldIndex === -1) {
            throw new Error(`No dependant field found with name ${dependantField.field} for field ${currentField.name}.`)
          }

          return this.currentForm[foundFieldIndex].value?.toString() === dependantField.value?.toString()
        })
        .includes(true)
    },
    checkDependenciesAnd(currentField) {
      // handles questions with multiple dependencies on parent answers
      return this.checkDependenciesOn(null, currentField.dependencies[0]) && this.checkDependenciesOr(null, currentField.dependencies[1])
    },
    shouldDisplay(currentField) {
      if (currentField?.dependencyRule === 'on') return this.checkDependenciesOn(currentField)

      if (currentField?.dependencyRule === 'or') return this.checkDependenciesOr(currentField)

      if (currentField?.dependencyRule === 'and') return this.checkDependenciesAnd(currentField)

      return this.checkDependencies(currentField)
    },
    checkIsDisabled(fieldName) {
      // used to apply disabled CSS class
      return this.disabledFields.includes(fieldName)
    },
    // Handles the rules of the field based on the enabled/disabled state.
    handleFieldRules(field, shouldSaveRules) {
      if (shouldSaveRules) {
        // Saves the original rules for the field and set the new rules to null.
        this.fieldsRules = {
          ...this.fieldsRules,
          [field.name]: field.rules
        }
        return null
      } else {
        // Gets the original saved rules, deletes the entries of the original rules from the memory and sets the rules back to its original state.
        const rulesToReturn = this.fieldsRules[field.name]
        return rulesToReturn
      }
    },
    handleDisableField(checkboxField, value) {
      if (!checkboxField.fieldToDisable) return

      const fieldsToDisable = checkboxField.fieldToDisable.split(';')
      // adds/removes fields from this.disabledFields depending on value which is a checkbox value true/false
      if (value) {
        this.disabledFields = [...this.disabledFields, ...fieldsToDisable]
      } else {
        this.disabledFields = this.disabledFields.filter(field => !fieldsToDisable.includes(field))
      }

      for (const fieldToDisableName of fieldsToDisable) {
        const fieldToDisable = this.form.find(field => field.name === fieldToDisableName)
        if (!fieldToDisable) continue

        this.currentForm = this.currentForm.map(field => {
          if (field.name === fieldToDisable.name) {
            // updates this.form with updated field - adds/removes rules from disabled fields, sets field value to false and toggles disabled true/false.
            return { ...fieldToDisable, value: null, rules: this.handleFieldRules(fieldToDisable, value), disabled: value }
          }
          return field
        })
      }
    },
    // Recursive function to reset children dependent values on the form once the parent value has changed.
    resetDependantValues(field) {
      const dependencies = this.form.filter(formField => formField?.dependencies && formField.dependencies.map(dep => dep.field).includes(field.name))

      if (dependencies.length) {
        dependencies.forEach(dependency => {
          this.form.find(formField => formField.name === dependency.name).value = null

          if (dependency.type === 'file' && dependency.dataType === 'file') {
            if (this.formFileToUpload) this.setFormFileToUpload(null)
            if (this.fileInfo?.fieldIndex && this.fileInfo?.documentId) {
              this.handleFileDelete(this.fileInfo.documentId, this.fileInfo.fieldIndex)
              this.fileInfo = null
              this.fileToUpload = null
            }
          }

          this.resetDependantValues(dependency)
        })
      }
    },
    handleEmittedInput(emmitedInput, field, index) {
      this.formIsTouched = true
      return this.fieldsActions[emmitedInput.action](field, emmitedInput.value, index)
    },
    handleValueChange(field, newValue) {
      const isFetchDependency = this.fetchDependancyMap[field.name]

      if (isFetchDependency) {
        this.fetchOptionsFromDependancy(field, newValue)
      }
      // handles checkbox field disabling
      this.handleDisableField(field, newValue)

      this.resetDependantValues(field)
      this.formIsTouched = true
      field.value = newValue
    },
    async fetchOptionsFromDependancy(parentField, parentFieldValue) {
      /*
      this function handles the 'requiredParentField' which is set in a fields metadata
      we intercept the input update from the requiredParentfield, make the request using the parents answer
      then append to the child.options. Any other approach breaks during the update cycle.
      */
      const childField = this.form.find(field => field.name === this.fetchDependancyMap[parentField.name])
      const entities = childField?.metadata?.optionsType
      const country = parentFieldValue
      if (!country || !entities) {
        return this.$toasted.show('Sorry, something went wrong..', { ...toastTopCenterOptions, type: 'error' })
      }
      try {
        const { data } = await getMetaData([entities], country)
        const options = data[entities]
        if (options) {
          childField.options = options
        }
      } catch (error) {
        this.$toasted.show('Sorry, something went wrong..', { ...toastTopCenterOptions, type: 'error' })
      }
    },
    async handleFileUpload(field, file, fieldIndex) {
      this.fileInfo = { ...file.info, fieldIndex }
      const uploadedFile = file.file
      const maxFileSize = 20 * 1024 // 20Mb

      if (!uploadedFile) return

      if (uploadedFile.size >= maxFileSize * 1024) {
        alert(`Maximum file size is ${maxFileSize / 1024}Mb. \n
              The file you are trying to upload has ${uploadedFile.size / (1024 * 1024)}Mb`)

        return
      }

      this.setFormFileToUpload({
        file: uploadedFile,
        fieldId: field.fieldId,
        fileName: uploadedFile.name,
        fieldName: field.name
      })

      this.fileToUpload = {
        fieldId: field.fieldId,
        value: {
          documentId: '',
          name: uploadedFile.name
        }
      }
      this.mockFilesUpload(uploadedFile.name)
    },
    mockFilesUpload(fileName) {
      this.fileInfo = {
        ...this.fileInfo,
        documentId: ''
      }

      this.currentForm[this.fileInfo.fieldIndex].value = {
        documentId: '',
        name: fileName
      }
    },
    handleFileDelete(field, value, index) {
      this.setFormFileToDelete(value || this.fileInfo?.documentId)
      this.setFormFileToUpload(null)
      this.fileInfo = null
      this.fileToUpload = null
      if (this.currentForm[index]?.value) this.currentForm[index].value = null
    },
    mapFormData() {
      const formData = {}

      this.form.filter(field => field.name).forEach(field => this.pushToFormData(formData, field))

      return formData
    },
    pushToFormData(formData, { name, value, type }) {
      let newValue = value

      if (Array.isArray(value) || (type === 'file' && !this.fileToUpload)) {
        newValue = JSON.stringify(value)
      }

      if (type === 'file' && this.fileToUpload) {
        newValue = JSON.stringify(this.fileToUpload.value)
      }

      if (type === 'date' && typeof value === 'number') {
        newValue = new Date(new Date(value * 1000)).toISOString()
      }

      formData[name] = newValue
    },
    scrollToFirstInvalidField() {
      const invalidElements = document.querySelectorAll('.invalid')

      if (invalidElements && invalidElements.length) {
        invalidElements[0].scrollIntoView({ behavior: 'smooth' })
      }
    },
    async submit(valid) {
      if (valid) {
        if (this.fileInfo) {
          this.fileToUpload = {
            fieldId: this.fileInfo.fieldId,
            value: {
              name: this.fileInfo?.title || 'formFile',
              documentId: ''
            }
          }
        }

        this.$emit('submit', this.mapFormData())
      } else {
        if (this.isFormSkippable) this.$emit('submit', this.mapFormData())
        this.scrollToFirstInvalidField()
      }
    },
    createFetchDependancyMap(form) {
      form.forEach(field => {
        const requiredParentName = field?.metadata?.requiredParentField
        if (requiredParentName) {
          this.fetchDependancyMap[requiredParentName] = field.name
        }
      })
    }
  },
  watch: {
    form(newValue) {
      this.currentForm = newValue
      // creates a map of the { parentFieldName : childFieldName } where the child is dependant on the parents answer to fetch it's options.
      this.createFetchDependancyMap(this.currentForm)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';
@import '@/assets/styles/swoop/helper-tooltip.scss';

.blend-background {
  background-color: #f9fafb;
}

.form-container {
  border-radius: 8px;
  padding: 16px 50px 16px 50px;

  @include flex-display(column, flex-start, center);

  .loader-container {
    @include flex-display(column, center, center);

    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .title {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 600px;
    line-height: 32.28px;
    letter-spacing: -0.4px;
  }

  .subtitle {
    margin-bottom: 26px;
    font-size: 14px;
    font-weight: 400px;
    line-height: 22px;
    letter-spacing: -0.35px;
    color: var(--color-primary-500);

    /deep/strong {
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .questions-container {
    @include flex-display(column, flex-start, center);

    width: 100%;
    gap: 16px;

    .field-wrapper:not(.with-child) + .with-child {
      .child {
        &:before {
          background-color: var(--color-neutral-50);
          top: 0;
        }
      }
    }

    .field-wrapper {
      position: relative;
      display: contents;

      label {
        width: 100%;
        display: flex;
        position: relative;
        top: 14px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.4px;
        padding-right: 16px;
        margin-bottom: 14px;
        color: var(--color-primary-500);

        &.error {
          font-weight: 500;
          color: var(--color-error-600);
        }
      }

      .child {
        width: 8px;
        position: relative;
        margin-right: 24px;
        background: var(--color-neutral-50);

        &:before {
          content: '';
          width: 8px;
          height: 100%;
          position: absolute;
          bottom: 32px;
          background-color: var(--color-neutral-50);
        }
      }

      .validator-provider-container {
        width: 100%;

        &.child-container {
          margin-bottom: 0;
        }
      }

      .checkbox-wrapper {
        margin-bottom: 14px;
      }

      .errors-container {
        @include flex-display(row, flex-start, flex-start);

        width: 376px;
        margin-top: 32px;
        padding: 12px;
        border: 1px solid var(--color-error-600);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.4px;
        background-color: var(--color-error-50);
        color: var(--color-error-600);

        em {
          margin-right: 8px;
        }

        span {
          margin-top: 2px;
        }
      }

      /deep/.field {
        @include flex-display(row, unset, space-between);

        margin-bottom: 0;

        .label {
          &.both {
            width: 0;
            padding-right: 0;
          }
        }

        .child-label + .errors-container {
          width: 356px;
        }

        .child-label {
          width: 570px;
        }

        .file-field {
          flex-direction: column;
        }
      }

      .field-data {
        @include flex-display(row, flex-start, space-between);

        width: 100%;
        position: relative;

        .texts-wrapper {
          width: 50%;

          label {
            width: auto;
          }
        }

        .input-wrapper {
          @include flex-display(column, flex-start, flex-end);

          width: 524px;
          position: relative;
          margin-left: 29px;

          &.both {
            width: 761px;
            flex: none;
            margin: 0;
          }

          &.no-tooltip {
            width: 524px;

            &.both {
              width: 100%;
            }
          }
        }
      }

      .currency-input-wrapper {
        /deep/input {
          width: 524px;
        }

        /deep/span {
          top: 12px;
        }
      }
    }

    .field-wrapper {
      &.with-child {
        .input-wrapper {
          margin-left: 0;
        }
      }

      .field-data {
        .input-wrapper {
          width: 50%;
          margin-right: 18px;

          &.no-tooltip {
            width: 50%;
          }

          .currency-input-wrapper {
            width: 100%;

            /deep/input {
              width: 100%;
            }
          }

          .checkbox-wrapper {
            width: 100%;
          }
        }
      }
    }
  }

  .controls {
    @include flex-display(row, center, space-between);

    width: 100%;
    justify-content: right;

    .skip-button {
      cursor: pointer;
      background-color: #f9fafb;
      width: fit-content;
      min-width: fit-content;
      margin-top: 26px;
      gap: 4px;
      padding: 16px 32px;
      border: 1px solid #dee8ec;
      border-radius: 73px;
      font-size: 16px;
    }

    .button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 32px 40px auto auto;

      /deep/.custom-button {
        @include flex-display(row, center, space-between);
        width: fit-content;
        min-width: fit-content;
        gap: 4px;
        padding: 16px 32px;
        border: none;
        border-radius: 73px;
        font-size: 16px;
        font-weight: 600;

        &:hover {
          cursor: pointer;
        }

        span.material-icons {
          font-size: 20px;
        }
      }

      .agree-text {
        margin: 12px 16px auto auto;
      }
    }
  }

  @media only screen and (max-width: 830px) {
    .questions-container {
      .field-wrapper {
        .field-data {
          width: 100%;
          flex-direction: column;

          .texts-wrapper {
            width: 100%;
          }

          label {
            top: 0;
          }

          label,
          .child-label,
          .input-wrapper {
            justify-content: flex-start;
            width: 100%;
            max-width: calc(100% - 42px);
            margin-left: 0;
            margin-bottom: 0;

            &.both {
              width: auto;
            }

            &.no-tooltip {
              width: 100%;
              margin-right: 42px;
            }

            .checkbox-wrapper {
              top: 8px;
              max-width: 100%;
              margin-bottom: 8px;
            }
          }
        }

        .errors-container {
          width: 100%;
          max-width: calc(100% - 42px);
          margin-top: 8px;
          margin-bottom: 8px;
        }

        /deep/.field {
          .child-label + .errors-container {
            width: 100%;
            margin-bottom: 8px;
          }
        }

        .child {
          margin-left: 0px;
          margin-right: 16px;
        }

        .currency-input-wrapper {
          width: 100%;

          /deep/input {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 12px;
  }
}
@media only screen and (max-width: 830px) {
  .form-container {
    padding: 15px;
  }
}
</style>
